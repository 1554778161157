<template>
  <div class="wrp">
    <div class="xy-tid">慧意计算机隐私政策</div>
    <div class="cont">
      <h3>一、信息收集</h3>
      <p>我们深知个人信息保护的重要性，因此，在收集您的信息时，我们将严格遵守法律法规的要求，坚持合法、正当、必要和诚信的原则。我们收集的信息主要包括以下几类：</p>
      <span>1.您主动提供的信息：</span>
      <p>1.1当您注册或使用我们的产品或服务时，您可能需要主动填写或上传昵称、手机号码、头像、图片等个人信息。</p>
      <p>1.2您通过我们的客服或参加我们举办的活动时所提交的信息也会被收集。</p>
      <p>1.3对于某些特定的服务，我们可能需要您提供特定的个人信息来实现相关功能。若您选择不提供该类信息，将不会影响您使用其他功能。</p>
      <span>2.我们在您使用服务时获取的信息：</span>
      <p>2.1日志信息：为了维护产品与服务的安全稳定运行，我们可能会收集设备信息、软件信息、服务日志信息等。</p>
      <p>2.2位置信息：当您使用与位置有关的服务时，我们可能会记录您的位置信息，以便为您提供更精准的服务。请注意，GPS位置信息是敏感个人信息，若您拒绝提供，将仅影响与地理位置相关的功能。</p>
      <p>2.3其他相关信息：根据您的授权，我们可能收集例如通讯录等加密后的信息，以帮助您更好地使用我们的服务。</p>
      <p>2.4其他用户分享的信息：其他用户发布的照片或分享的视频中可能包含您的信息，我们提醒您注意隐私安全。</p>
      <span>3.法律允许情况下获得的信息：例如，您使用微信或QQ账户登录进行第三方合作伙伴服务时，我们会获得您登录的名称等信息，以方便您进行授权管理。</span>
    </div>

    <div class="cont">
      <h3>二、信息使用</h3>
      <p style="text-indent:0px;margin-left: 3.6rem;">我们将严格遵守法律法规的规定及与用户的约定，将收集的信息用于以下用途：</p>
      <span style="margin-left: 6.2rem;">1.向您提供服务：满足您的基本服务需求。</span>
      <span style="margin-left: 6.2rem;">2.满足个性化需求：如语言设定、位置设定、个性化的帮助服务等。</span>
      <span style="margin-left: 6.2rem;">3.安全保障：用于身份验证、安全防范、反诈骗监测、存档备份等。</span>
      <span style="margin-left: 6.2rem;">4.广告推荐：向您推荐可能感兴趣的广告、资讯等。</span>
      <span style="margin-left: 6.2rem;">5.管理软件：进行软件认证、升级等。</span>
      <span style="margin-left: 6.2rem;">6.服务调查：邀请您参与有关我们服务的调查，以改善我们的服务质量。</span>
      <span style="margin-left: 6.2rem;">我们承诺，若超出上述用途使用您的信息，我们将再次向您说明，并征得您的同意。</span>
    </div>

    <div class="cont">
      <h3>三、信息管理</h3>
      <p>您可以通过查阅、复制、更正、补充、删除等方式来管理您的信息。我们提供了便捷的隐私设置选项，允许您控制分享信息的范围。但请注意，即使您删除了某些信息，这些信息仍可能由其他用户或不受我们控制的非关联第三方独立保存。</p>
    </div>
    <div class="cont">
      <h3>四、第三方数据处理及信息的公开披露</h3>
      <p>我们与第三方合作时，将遵守法律规定，按照最小必要原则处理相关数据。我们要求第三方服务商严格遵守我们的数据隐私保护措施，保障数据安全。我们不会主动公开披露您的个人信息，除非经您单独同意或法律法规另有规定。</p>
    </div>

    <div class="cont">
      <h3>五、信息存储与安全</h3>
      <span>1.存储地点：我们在中华人民共和国境内收集和产生的个人信息将存储在境内。</span>
      <span>2.存储期限：我们仅在为实现目的所必需的最短时间内保留您的个人信息，但也会根据法律法规要求延长存储期限。</span>
      <span>3.安全保障：我们采用加密技术、匿名化处理等手段保护您的个人信息，并建立专门的管理制度确保信息安全。</span>
    </div>

    <div class="cont">
      <h3>六、广告</h3>
      <p>我们可能向您提供个性化广告服务，但您可以选择关闭。我们严格遵守相关法律法规，确保广告内容的合法性和适宜性。</p>
    </div>
    <div class="cont">
      <h3>七、未成年人保护</h3>
      <p>我们非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用我们的服务前，应取得家长或法定监护人的同意。对于14周岁以下的儿童，我们要求其在家长或监护人的帮助下完成注册和使用流程，并仔细阅读相关隐私政策和儿童隐私保护声明。</p>
    </div>
    <div class="cont">
      <h3>八、适用范围</h3>
      <p>本隐私政策适用于由深圳市慧意计算机有限责任公司提供的所有产品和服务。若存在特定服务的隐私指引/声明与本政策不一致之处，请以特定隐私指引/声明为准。</p>
    </div>

  </div>

</template>
    
    <script>
export default {
  data() {
    return {
      Height: 0,
    };
  },
  created() {},

  methods: {},
};
</script>
    <style scoped>
.wrp {
  width: 130rem;
  margin: 10.5rem auto 0px;
  text-align: left;
  padding-bottom: 10rem;
}
.xy-tid {
  text-align: center;
  padding-top: 10rem;
  font-size: 3.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 8rem;
}
.cont {
  margin-top: 3rem;
}
.cont h3 {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.cont span {
  margin-left: 3.6rem;
  font-size: 1.6rem;
  font-weight: bold;
  display: block;
  margin-bottom: 2rem;
}
.cont p {
  margin-left: 6.2rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  text-align: justify;
  text-indent: 1.6rem;
  line-height: 2.5rem;
}


</style>